// eslint-disable-next-line
import { Desktop, Mobile, Tablet } from "./mediaQuery";
import DesktopContainer from "./DesktopContainer";
import MobileContainer from "./MobileContainer";

function App() {
  return (
    <>
      <Desktop>
        <DesktopContainer />
      </Desktop>
      <Tablet>
        <MobileContainer />
      </Tablet>
      <Mobile>
        <MobileContainer />
      </Mobile>
    </>
  );
}

export default App;
