import styled from "styled-components";
import { motion } from "framer-motion";
import eagle from "../../assets/img/eagle.jpg";
import inmoon from "../../assets/img/inmoon.jpg";
// import library from "../../assets/img/library.jpg";

const Container = styled.div`
  height: 500px;
  width: 100%;
  display: flex;
  margin-bottom: 30px;
  /* background-color: lime; */
`;

const LeftWrapper = styled(motion.div)`
  height: 100%;
  width: 50%;
  /* padding: 100px 100px; */
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: aliceblue; */
`;

const TowerImage = styled(motion.img)`
  height: 90%;
  width: auto;
  border-radius: 24px;
  mask-image: gradient(
    linear,
    left top,
    left bottom,
    from(rgba(0, 0, 0, 1)),
    to(rgba(0, 0, 0, 0))
  );
  -webkit-mask-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgba(0, 0, 0, 1)),
    to(rgba(0, 0, 0, 0.15))
  );
  box-shadow: -1px -1px 5px 1px lime, 1px 1px 5px 1px blue;
`;

const RightWrapper = styled(motion.div)`
  height: 100%;
  width: 50%;
  padding: 80px 80px;
  display: flex;
  flex-direction: column;
  /* background-color: pink; */
`;

const Title = styled(motion.div)`
  height: 70px;
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 1.8rem;
  letter-spacing: 0.01rem;
  color: #005baa;
  background: linear-gradient(to left, #00a651, #005baa);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  border-left: 5px solid;
  padding-left: 15px;
  border-image: linear-gradient(to bottom, #005baa, #00a651) 0 0 0 1;
  margin-bottom: 20px;
  /* line-height: 65px; */
  /* background-color: darkblue; */
`;

const Description = styled(motion.div)`
  height: 400px;
  width: 100%;
  font-size: 1.8rem;
  line-height: 1.2;
  margin-top: 20px;
  display: flex;
  background: linear-gradient(to left, #00a651, #005baa);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  /* background-color: darkcyan; */
`;

function Content() {
  return (
    <>
      <Container>
        <LeftWrapper>
          <TowerImage
            src={eagle}
            initial={{
              x: -100,
              opacity: 0,
            }}
            animate={{
              x: 0,
              opacity: 1,
            }}
            transition={{
              duration: 1,
              ease: "easeInOut",
            }}
          />
        </LeftWrapper>
        <RightWrapper>
          <Title
            initial={{
              x: 100,
              opacity: 0,
            }}
            animate={{
              x: 0,
              opacity: 1,
            }}
            transition={{
              duration: 1,
              ease: "easeInOut",
            }}
          >
            PUBL, For Public Blockchain Society.
          </Title>
          <Description
            initial={{
              x: 100,
              opacity: 0,
            }}
            animate={{
              x: 0,
              opacity: 1,
            }}
            transition={{
              duration: 1,
              ease: "easeInOut",
            }}
          >
            PUBL is a student-run blockchain organization which began in 2018 as
            Investment Club at first, but reorganized as Blockchain Research
            Focused Club in 2022.
          </Description>
        </RightWrapper>
      </Container>
      <Container>
        <RightWrapper>
          <Title
            initial={{
              x: -100,
              opacity: 0,
            }}
            animate={{
              x: 0,
              opacity: 1,
            }}
            transition={{
              duration: 1,
              ease: "easeInOut",
            }}
          >
            Our Mission
          </Title>
          <Description
            initial={{
              x: -100,
              opacity: 0,
            }}
            animate={{
              x: 0,
              opacity: 1,
            }}
            transition={{
              duration: 1,
              ease: "easeInOut",
            }}
            style={{
              fontFamily: "LineSeedKRRegular",
            }}
          >
            Our primary mission is to provide students with education,
            community, and possibilites within the field of blockchain. Our club
            is searching to recruit Pusan National University students who are
            engaged, dedicated, and innovative to join our team. We also rely on
            our sponsors to support our organization.
          </Description>
        </RightWrapper>
        <LeftWrapper>
          <TowerImage
            src={inmoon}
            initial={{
              x: 100,
              opacity: 0,
            }}
            animate={{
              x: 0,
              opacity: 1,
            }}
            transition={{
              duration: 1,
              ease: "easeInOut",
            }}
          />
        </LeftWrapper>
      </Container>
    </>
  );
}

export default Content;
