import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  padding: 25px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* background-color: aquamarine; */
`;

const Title = styled.div`
  width: 100%;
  height: 35px;
  display: flex;
  /* background-color: aliceblue; */
`;

const TitleSpan = styled.span`
  height: 100%;
  font-size: 2.1rem;
  background: linear-gradient(to right, #00a651, #005baa);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  /* background-color: aliceblue; */
`;

const ContentWrapper = styled.div`
  width: 100%;
  height: auto;
  padding-top: 20px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  border: 1px solid #ececec;
  border-image: linear-gradient(to right, #005baa 0%, #00a651 100%);
  border-image-slice: 1;
`;

const ContentTitle = styled.div`
  width: 100%;
  height: 12%;
  display: flex;
  font-size: 1.5rem;
  font-weight: 700;
  justify-content: center;
  align-items: center;
  background: linear-gradient(to right, #00a651, #005baa);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const ContentRow = styled.div`
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  font-family: LineSeedKRRegular;
  /* background-color: pink; */
`;

const ContentKey = styled.div`
  width: 80px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  font-weight: 600;
  border: 1px solid #ececec;
  border-image: linear-gradient(to right, #005baa 0%, #00a651 100%);
  border-image-slice: 1;
  background: linear-gradient(to right, #00a651, #005baa);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  /* background-color: red; */
`;

const ContentValue = styled.div`
  width: 100%;
  height: 100%;
  padding: 0 20px;
  padding-top: 20px;
  display: flex;
  line-height: 1.2;
  font-size: 1rem;
  background: linear-gradient(to right, #00a651, #005baa);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  /* background-color: red; */
`;

const ApplyWrapper = styled.div`
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ApplyButton = styled.div`
  width: 150px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1.7;
  font-size: 1.1rem;
  font-weight: 700;
  background: linear-gradient(to right, #00a651, #005baa);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  border: 1px solid #ececec;
  border-image: linear-gradient(to right, #005baa 0%, #00a651 100%);
  border-image-slice: 1;
  cursor: pointer;
`;

function Recruit() {
  return (
    <Container>
      <Title>
        <TitleSpan>Recruit</TitleSpan>
      </Title>
      <ContentWrapper>
        <ContentTitle>PUBL 2기 선발 안내</ContentTitle>
        <ContentRow>
          <ContentKey>모집대상</ContentKey>
          <ContentValue>
            블록체인 기술에 대한 기본적인 이해가 있고, 블록체인 연구에 열정이
            있으신 분.
            <br />
            <br />
            입회 후, 만 1년간 활동이 가능하신 분.
          </ContentValue>
        </ContentRow>
        <ContentRow>
          <ContentKey>모집분야</ContentKey>
          <ContentValue>
            개발 분과
            <br />
            <br />
            금융 분과
            <br />
            <br />
            디자이너
          </ContentValue>
        </ContentRow>
        <ContentRow>
          <ContentKey>활동내용</ContentKey>
          <ContentValue>
            프로젝트 : Smart Contract, DApp, Token Economy 등의 주제로 진행
            <br />
            <br />
            정기세션 : 블록체인 현안 연구 및 발표, 교류
            <br />
            <br />
            기타활동 : 블록체인 관련 해커톤, 컨퍼런스 및 행사 참여
          </ContentValue>
        </ContentRow>
        <ContentRow>
          <ContentKey>지원기간</ContentKey>
          <ContentValue>
            {" "}
            2023. 3. 1 ~ 2023. 3. 11
            <br />
            기타 문의사항 : &nbsp;
            <a
              style={{ display: "contents" }}
              href="https://discord.gg/NYyfRYh6"
              target="_blank"
              rel="noreferrer"
            >
              학회 디스코드 지원문의 채널
            </a>
          </ContentValue>
        </ContentRow>
      </ContentWrapper>
      <ApplyWrapper>
        <ApplyButton
          as="a"
          href="https://docs.google.com/forms/d/e/1FAIpQLSeL6C18yEccPd1ZhK8xnaonFxsLlAvKRhP6LGZh5UWNzFwzbg/viewform"
          target="_blank"
          rel="noreferrer"
        >
          지원하기
        </ApplyButton>
      </ApplyWrapper>
    </Container>
  );
}

export default Recruit;
