import { createGlobalStyle } from "styled-components";
import reset from "styled-reset";

import ChakraPetchRegular from "./assets/fonts/ChakraPetch-Regular.ttf";
import ChakraPetchMedium from "./assets/fonts/ChakraPetch-Medium.ttf";
import ChakraPetchBold from "./assets/fonts/ChakraPetch-Bold.ttf";

import LineSeedKRBold from "./assets/fonts/LINESeedKR-Bd.ttf";
import LineSeedKRRegular from "./assets/fonts/LINESeedKR-Rg.ttf";
import LineSeedKRThin from "./assets/fonts/LINESeedKR-Th.ttf";

const GlobalStyles = createGlobalStyle`
    ${reset}

    @font-face {
        font-family: 'ChakraPetchRegular';
        src: local('ChakraPetchRegular'), local('ChakraPetchRegular');
        font-style: normal;
        src: url(${ChakraPetchRegular}) format('truetype');
    }
    @font-face {
        font-family: 'ChakraPetchMedium';
        src: local('ChakraPetchMedium'), local('ChakraPetchMedium');
        font-style: normal;
        src: url(${ChakraPetchMedium}) format('truetype');
    }
    @font-face {
        font-family: 'ChakraPetchBold';
        src: local('ChakraPetchBold'), local('ChakraPetchBold');
        font-style: normal;
        src: url(${ChakraPetchBold}) format('truetype');
    }

    @font-face {
        font-family: 'LineSeedKRBold';
        src: local('LineSeedKRBold'), local('LineSeedKRBold');
        font-style: normal;
        src: url(${LineSeedKRBold}) format('truetype');
    }
    @font-face {
        font-family: 'LineSeedKRRegular';
        src: local('LineSeedKRRegular'), local('LineSeedKRRegular');
        font-style: normal;
        src: url(${LineSeedKRRegular}) format('truetype');
    }
    @font-face {
        font-family: 'LineSeedKRThin';
        src: local('LineSeedKRThin'), local('LineSeedKRThin');
        font-style: normal;
        src: url(${LineSeedKRThin}) format('truetype');
    }



    a{
        text-decoration: none;
        color: inherit;
    }
    *{
        box-sizing: border-box;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }
    body {
        /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif; */
        /* font-size: 14px; */
        /* background-color: rgba(20, 20, 20, 1); */
        /* color: white; */
        /* padding-top: 50px; */
        padding-top: 56px;
        color: white;
        background-color: black;
        font-family: ChakraPetchRegular;
        overflow-x: hidden;
    }
`;

export default GlobalStyles;
