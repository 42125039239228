import { useEffect } from "react";
import useHover from "../utils/UseHover";
import styled from "styled-components";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

const Container = styled.div`
  width: 100%;
  height: 400px;
  padding: 0 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* background-color: lemonchiffon; */
`;

const Title = styled.div`
  width: 100%;
  height: 40px;
  /* background-color: aliceblue; */
`;

const TitleSpan = styled.span`
  height: 100%;
  font-size: 2.1rem;
  background: linear-gradient(to right, #00a651, #005baa);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const CategoryWrapper = styled.div`
  width: 100%;
  height: 360px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Category = styled(motion.div)`
  width: 300px;
  height: 300px;
  margin: 0 40px;
  display: flex;
  border: 1px solid #ececec;
  border-image: linear-gradient(to right, #005baa 0%, #00a651 100%);
  border-image-slice: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  &:hover {
    cursor: default;
    background: linear-gradient(to left, #00a651, #005baa);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  /* background-color: aliceblue; */
`;

const CategoryTitleContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
`;

const CategoryDetailContent = styled.div`
  width: 100%;
  height: 100%;
  margin: 0 10%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  line-height: 1.4;
  font-size: 1rem;
  /* font-family: LineSeedKRRegular; */
`;

const variantsOne = {
  initial: {
    x: -70,
    opacity: 0,
  },
  animate: {
    x: 0,
    opacity: 1,
  },
};

const variantsTwo = {
  initial: {
    y: -70,
    opacity: 0,
  },
  animate: {
    y: 0,
    opacity: 1,
  },
};

const variantsThree = {
  initial: {
    y: 70,
    opacity: 0,
  },
  animate: {
    y: 0,
    opacity: 1,
  },
};

const variantsFour = {
  initial: {
    x: 70,
    opacity: 0,
  },
  animate: {
    x: 0,
    opacity: 1,
  },
};

function ResearchThemes() {
  const [hoverOne, hoverPropsOne] = useHover();
  const [hoverTwo, hoverPropsTwo] = useHover();
  const [hoverThree, hoverPropsThree] = useHover();
  const [hoverFour, hoverPropsFour] = useHover();

  const controls = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      controls.start("animate");
    }
    // else {
    //   controls.set("initial"); // animation starts on every inView Status
    // }
  }, [controls, inView]);

  return (
    <Container>
      <Title>
        <TitleSpan>Research Themes</TitleSpan>
      </Title>
      <CategoryWrapper ref={ref}>
        <Category
          variants={variantsOne}
          initial="initial"
          animate={controls}
          transition={{
            duration: 1,
            ease: "easeInOut",
          }}
          {...(hoverPropsOne as Object)}
        >
          {hoverOne ? (
            <CategoryDetailContent>
              Smart Contract가 어떻게 동작하는지 알아보고, Solidity나 Rust와
              같은 언어를 통해 직접 구현해봅니다.
            </CategoryDetailContent>
          ) : (
            <CategoryTitleContent>Smart Contract</CategoryTitleContent>
          )}
        </Category>
        <Category
          variants={variantsTwo}
          initial="initial"
          animate={controls}
          transition={{
            duration: 1,
            ease: "easeInOut",
          }}
          {...(hoverPropsTwo as Object)}
        >
          {hoverTwo ? (
            <CategoryDetailContent>
              여러 Token Economy의 설계 방식을 알아보고, 직접 토큰에 적용해보는
              과정을 경험을 접해봅니다.
            </CategoryDetailContent>
          ) : (
            <CategoryTitleContent>Token Economy</CategoryTitleContent>
          )}
        </Category>
        <Category
          variants={variantsThree}
          initial="initial"
          animate={controls}
          transition={{
            duration: 1,
            ease: "easeInOut",
          }}
          {...(hoverPropsThree as Object)}
        >
          {hoverThree ? (
            <CategoryDetailContent>
              DeFi가 어떻게 작동되는지 알아보고, Uniswap 등의 실제 Product들의
              사례를 연구해봅니다.
            </CategoryDetailContent>
          ) : (
            <CategoryTitleContent>DeFi</CategoryTitleContent>
          )}
        </Category>
        <Category
          variants={variantsFour}
          initial="initial"
          animate={controls}
          transition={{
            duration: 1,
            ease: "easeInOut",
          }}
          {...(hoverPropsFour as Object)}
        >
          {hoverFour ? (
            <CategoryDetailContent>
              NFT의 기술 표준을 알아보고, 어떻게 구현되는지 알아봅니다. 직접 NFT
              Product를 만들어보고, NFT의 다양한 활용 가능성을 연구해봅니다.
            </CategoryDetailContent>
          ) : (
            <CategoryTitleContent>NFT</CategoryTitleContent>
          )}
        </Category>
      </CategoryWrapper>
    </Container>
  );
}

export default ResearchThemes;
