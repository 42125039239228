import styled from "styled-components";
import { motion } from "framer-motion";

import { Carousel } from "react-responsive-carousel";
import ModalImage from "react-modal-image";

import publOT from "../../assets/img/publOT.png";
import publOT2 from "../../assets/img/publOT2.png";
import publOT3 from "../../assets/img/publOT3.png";
import financeOT from "../../assets/img/financeOT.png";
import financeOT2 from "../../assets/img/financeOT2.png";
import financeOT3 from "../../assets/img/financeOT3.png";
import financeOT4 from "../../assets/img/financeOT4.png";
import oldLogo from "../../assets/img/oldLogo.png";

const Container = styled.div`
  width: 100%;
  height: 310px;
  padding: 0 20px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  /* background: url(${publOT2}), url(${publOT}); */
  /* background-color: pink; */
`;

const Title = styled.div`
  width: 100%;
  height: 50px;
  /* background-color: aliceblue; */
`;

const TitleSpan = styled.span`
  height: 100%;
  font-size: 2.1rem;
  background: linear-gradient(to right, #00a651, #005baa);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const CategoryCarousel = styled(Carousel)`
  // carousel-root
  width: 100%;
  height: 260px;
  display: flex;
  justify-content: center;
  align-items: center;
  div {
    // carousel-slider
    width: 100% !important;
    height: 100% !important;
    display: flex;
    justify-content: center;
    align-items: center;
    div {
      // slider-wrapper
      height: 100% !important;
      width: 100% !important;
      ul {
        height: 100% !important;
        li {
          height: 100% !important;
          div {
            div {
              img {
                /* height: 100% !important; */
              }
            }
          }
        }
      }
    }
  }
`;

const Category = styled(motion.div)`
  /* width: 300px; */
  /* height: 300px; */
  width: 100%;
  height: 100%;
  border: 1px solid #ececec;
  border-image: linear-gradient(to right, #005baa 0%, #00a651 100%);
  border-image-slice: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  /* background-color: aliceblue; */
  div {
    max-width: 100%;
    max-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const Img = styled(ModalImage)`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  /* object-fit: contain; */
  opacity: 0.8;
  z-index: 2;
  img {
    max-height: 100%;
    object-fit: contain;
  }
`;

function Activities() {
  return (
    <Container>
      <Title>
        <TitleSpan>Activities</TitleSpan>
      </Title>
      <CategoryCarousel
        showArrows={true}
        infiniteLoop={true}
        showStatus={false}
        showThumbs={false}
        showIndicators={false}
        preventMovementUntilSwipeScrollTolerance={true}
        swipeScrollTolerance={50}
      >
        <Category>
          <Img
            small={publOT}
            middle={publOT}
            large={publOT}
            hideDownload={true}
            hideZoom={true}
          />
        </Category>
        <Category>
          <Img
            small={publOT2}
            large={publOT2}
            hideDownload={true}
            hideZoom={true}
          />
        </Category>
        <Category>
          <Img
            small={publOT3}
            large={publOT3}
            hideDownload={true}
            hideZoom={true}
          />
        </Category>
        <Category>
          <Img
            small={financeOT}
            large={financeOT}
            hideDownload={true}
            hideZoom={true}
          />
        </Category>
        <Category>
          <Img
            small={financeOT3}
            large={financeOT3}
            hideDownload={true}
            hideZoom={true}
          />
        </Category>
        <Category>
          <Img
            small={financeOT4}
            large={financeOT4}
            hideDownload={true}
            hideZoom={true}
          />
        </Category>
        <Category>
          <Img
            small={financeOT2}
            large={financeOT2}
            hideDownload={true}
            hideZoom={true}
          />
        </Category>
        <Category>
          <Img
            small={oldLogo}
            large={oldLogo}
            hideDownload={true}
            hideZoom={true}
          />
        </Category>
      </CategoryCarousel>
    </Container>
  );
}

export default Activities;
