import styled from "styled-components";
import { FaGithub, FaMedium, FaDiscord } from "react-icons/fa";

const Container = styled.footer`
  width: 100%;
  height: 300px;
  padding: 20px 20px;
  padding-bottom: 70px;
  display: flex;
  flex-direction: column;
  background: linear-gradient(to bottom, black, rgb(10, 10, 10));
  border-top: 1px solid #ececec;
  border-image: linear-gradient(to left, #005baa 0%, #00a651 100%);
  border-image-slice: 1;
`;

const Title = styled.div`
  width: 100%;
  height: 30%;
  /* background-color: aliceblue; */
`;

const TitleSpan = styled.span`
  height: 100%;
  font-size: 1.6rem;
  color: #555;
  display: flex;
  align-items: center;
  /* font-style: italic; */
`;

const KoreanTitle = styled.div`
  width: 100%;
  height: 15%;
  color: #999;
  display: flex;
  align-items: center;
  font-family: "LineSeedKRRegular";
  /* background-color: aliceblue; */
`;

const EnglishTitle = styled.div`
  width: 100%;
  height: 15%;
  color: #999;
  display: flex;
  align-items: center;
  font-family: "LineSeedKRRegular";
  /* background-color: aliceblue; */
`;

const Content = styled.div`
  width: 100%;
  height: 40%;
  display: flex;
  /* background-color: red; */
`;

const LeftContent = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  /* background-color: lime; */
`;

const RightContent = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  /* background-color: darkgray; */
`;

const SNSBox = styled.div`
  width: 30%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2.5rem;
  color: #555;
  cursor: pointer;
  &:hover {
    color: white;
  }
  /* background-color: aqua; */
`;

const EmailWrapper = styled.div`
  width: 100%;
  height: 40%;
  display: flex;
  align-items: center;
  color: #999;
  /* background-color: red; */
`;

const Email = styled.a`
  font-family: "LineSeedKRRegular";
`;

function Footer() {
  return (
    <Container>
      <Title>
        <TitleSpan>PUBL</TitleSpan>
      </Title>
      <KoreanTitle>부산대학교 블록체인 학회</KoreanTitle>
      <EnglishTitle>Pusan National University Blockchain Lab</EnglishTitle>
      <Content>
        <LeftContent>
          <EmailWrapper>
            <Email href="mailto:publ.pnu@gmail.com">publ.pnu@gmail.com</Email>
          </EmailWrapper>
        </LeftContent>
        <RightContent>
          <SNSBox
            as="a"
            href="https://github.com/PNU-PUBL"
            target="_blank"
            rel="noreferrer"
          >
            <FaGithub />
          </SNSBox>
          <SNSBox
            as="a"
            href="https://medium.com/publ-pnu"
            target="_blank"
            rel="noreferrer"
          >
            <FaMedium />
          </SNSBox>
          <SNSBox
            as="a"
            href="https://discord.gg/4GfHdFY9"
            target="_blank"
            rel="noreferrer"
          >
            <FaDiscord />
          </SNSBox>
        </RightContent>
      </Content>
    </Container>
  );
}

export default Footer;
