import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Header from "./components/mobile/Header";
import Content from "./components/mobile/Content";
import ResearchThemes from "./components/mobile/ResearchThemes";
import Activities from "./components/mobile/Activites";
import Recruit from "./components/mobile/Recruit";
import Partners from "./components/mobile/Partners";
import Footer from "./components/mobile/Footer";

import ScrollToTop from "./components/utils/ScrolltToTop";

function MobileContainer() {
  return (
    <>
      <Router>
        <Routes>
          <Route
            path="/recruit"
            element={
              <>
                <Header />
                <Recruit />
                <Footer />
                <ScrollToTop />
              </>
            }
          />
          <Route
            path="/"
            element={
              <>
                <Header />
                <Content />
                <ResearchThemes />
                <Activities />
                <Partners />
                <Footer />
                <ScrollToTop />
              </>
            }
          />
        </Routes>
      </Router>
    </>
  );
}

export default MobileContainer;
