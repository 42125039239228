import styled from "styled-components";
import { motion } from "framer-motion";
import logo from "../../assets/img/publLogo.png";
import { Link, useNavigate, useLocation } from "react-router-dom";

const Container = styled(motion.header)`
  height: 56px;
  width: 100%;
  border-bottom: 1px solid #ececec;
  border-image: linear-gradient(to right, #005baa 0%, #00a651 100%);
  border-image-slice: 1;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  background-color: black;
`;

const Contents = styled.div`
  height: 100%;
  max-width: 1600px;
  margin: 0 auto;
  display: flex;
  /* background-color: white; */
  /* z-index: 10; */
`;

const ImageWrapper = styled.div`
  width: 20%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 20px;
  /* justify-content: center; */
  /* background-color: pink; */
`;

const Img = styled.img`
  height: 60%;
  width: auto;
  cursor: pointer;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
`;

const MenuWrapper = styled.div`
  width: 80%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  /* background-color: blue; */
`;

const Menu = styled(Link)`
  width: 10%;
  height: 100%;
  margin: 0 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-weight: 700;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  &:hover {
    background: linear-gradient(to left, #00a651, #005baa);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  /* background-color: darkblue; */
`;

function Header() {
  const navigate = useNavigate();
  const location = useLocation();

  const handleHome = () => {
    if (location.pathname === "/") {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    } else {
      navigate(`/`);
    }
  };

  return (
    <Container
      initial={{
        y: -70,
        opacity: 0,
      }}
      animate={{
        y: 0,
        opacity: 1,
      }}
      transition={{
        duration: 1,
        ease: "easeInOut",
      }}
    >
      <Contents>
        <ImageWrapper>
          <Img src={logo} onClick={handleHome} />
        </ImageWrapper>
        <MenuWrapper>
          <Menu to="/recruit">Recruit</Menu>
          <Menu
            as="a"
            href="https://medium.com/publ-pnu"
            target="_blank"
            rel="noreferrer"
          >
            Medium
          </Menu>
        </MenuWrapper>
      </Contents>
    </Container>
  );
}

export default Header;
