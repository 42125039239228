import styled, { keyframes } from "styled-components";
// import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

import pnulogo from "../../assets/img/pnulogo.png";
import a41 from "../../assets/img/a41logo.png";
import near from "../../assets/img/nearLogo.png";
import nearKrLogo from "../../assets/img/nearKrLogo.svg";
import debutlerLogo from "../../assets/img/debutlerLogo.png";
import hashed from "../../assets/img/hashedlogo.png";
import groundx from "../../assets/img/groundxLogo.png";
import bsc from "../../assets/img/bscLogo.png";
import klaytn from "../../assets/img/klaytnLogo.png";
import bsepcial from "../../assets/img/bspecial.png";

const Container = styled.div`
  width: 100%;
  height: 280px;
  padding: 0 80px;
  margin: 50px 0;
  padding-top: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* background-color: red; */
`;

const Title = styled.div`
  width: 100%;
  height: 45px;
  /* background-color: aliceblue; */
`;

const TitleSpan = styled.span`
  height: 100%;
  font-size: 2.1rem;
  letter-spacing: 0.05rem;
  background: linear-gradient(to right, #00a651, #005baa);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const Slider = styled.div`
  height: 100px;
  margin: auto;
  overflow: hidden;
  position: relative;
  width: 100%;
`;

const $Scroll = keyframes`
	0% { transform: translateX(0); }
	100% { transform: translateX(calc(-250px * 6))}
`;

const SlideTrack = styled.div`
  animation: ${$Scroll} 45s linear infinite;
  display: flex;
  /* width: calc(250px * 12); */
  width: 100%;
`;

const Slide = styled.div`
  height: 100px;
  width: 250px;
  margin: 0 40px;
  display: flex;
  align-items: center;
`;

const Image = styled.img`
  width: 250px;
  height: auto;
`;

function Partners() {
  return (
    <Container>
      <Title>
        <TitleSpan>Affilation</TitleSpan>
      </Title>
      <Slider>
        <SlideTrack>
          <Slide>
            <Image src={pnulogo} />
          </Slide>
          <Slide>
            <Image src={nearKrLogo} />
          </Slide>
          {/* <Slide>
            <Image src={a41} />
          </Slide> */}
          {/* <Slide>
            <Image src={near} />
          </Slide> */}
          <Slide>
            <Image src={debutlerLogo} />
          </Slide>
          {/* <Slide>
            <Image src={bsepcial} />
          </Slide>
          <Slide>
            <Image src={klaytn} />
          </Slide>
          <Slide>
            <Image src={hashed} />
          </Slide>
          <Slide>
            <Image src={groundx} />
          </Slide>
          <Slide>
            <Image src={bsc} />
          </Slide> */}
          <Slide>
            <Image src={pnulogo} />
          </Slide>
          <Slide>
            <Image src={nearKrLogo} />
          </Slide>
          {/* <Slide>
            <Image src={a41} />
          </Slide> */}
          {/* <Slide>
            <Image src={near} />
          </Slide> */}
          <Slide>
            <Image src={debutlerLogo} />
          </Slide>
          {/* <Slide>
            <Image src={bsepcial} />
          </Slide>
          <Slide>
            <Image src={klaytn} />
          </Slide>
          <Slide>
            <Image src={hashed} />
          </Slide>
          <Slide>
            <Image src={groundx} />
          </Slide>
          <Slide>
            <Image src={bsc} />
          </Slide> */}
        </SlideTrack>
      </Slider>
    </Container>
  );
}

export default Partners;
