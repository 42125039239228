import styled from "styled-components";
import { motion } from "framer-motion";

import ModalImage from "react-modal-image";

import publOT from "../../assets/img/publOT.png";
import publOT2 from "../../assets/img/publOT2.png";
import publOT3 from "../../assets/img/publOT3.png";
import financeOT from "../../assets/img/financeOT.png";
import financeOT2 from "../../assets/img/financeOT2.png";
import financeOT3 from "../../assets/img/financeOT3.png";
import financeOT4 from "../../assets/img/financeOT4.png";
import oldLogo from "../../assets/img/oldLogo.png";

const Container = styled.div`
  width: 100%;
  height: 750px;
  padding: 0 80px;
  padding-top: 50px;
  display: flex;
  flex-direction: column;
  /* background: url(${publOT2}), url(${publOT}); */
  /* background-color: pink; */
`;

const Title = styled.div`
  width: 100%;
  height: 50px;
  /* background-color: aliceblue; */
`;

const TitleSpan = styled.span`
  height: 100%;
  font-size: 2.1rem;
  background: linear-gradient(to right, #00a651, #005baa);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const GridContainer = styled.div`
  width: 100%;
  height: 600px;
  margin-top: 50px;
  /* padding: 0 80px; */
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  row-gap: 20px;
  column-gap: 10px;
`;

const Category = styled(motion.div)`
  /* width: 300px; */
  /* height: 300px; */
  border: 1px solid #ececec;
  border-image: linear-gradient(to right, #005baa 0%, #00a651 100%);
  border-image-slice: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  /* background-color: aliceblue; */
  div {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const Img = styled(ModalImage)`
  width: 90%;
  height: auto;
  display: flex;
  justify-content: center;
  /* object-fit: contain; */
  opacity: 0.8;
`;

function Activities() {
  return (
    <Container>
      <Title>
        <TitleSpan>Activities</TitleSpan>
      </Title>
      <GridContainer>
        <Category>
          <Img
            small={publOT}
            large={publOT}
            hideDownload={true}
            hideZoom={true}
          />
        </Category>
        <Category>
          <Img
            small={publOT2}
            large={publOT2}
            hideDownload={true}
            hideZoom={true}
          />
        </Category>
        <Category>
          <Img
            small={publOT3}
            large={publOT3}
            hideDownload={true}
            hideZoom={true}
          />
        </Category>
        <Category>
          <Img
            small={financeOT}
            large={financeOT}
            hideDownload={true}
            hideZoom={true}
          />
        </Category>
        <Category>
          <Img
            small={financeOT3}
            large={financeOT3}
            hideDownload={true}
            hideZoom={true}
          />
        </Category>
        <Category>
          <Img
            small={financeOT4}
            large={financeOT4}
            hideDownload={true}
            hideZoom={true}
          />
        </Category>
        <Category>
          <Img
            small={financeOT2}
            large={financeOT2}
            hideDownload={true}
            hideZoom={true}
          />
        </Category>
        <Category>
          <Img
            small={oldLogo}
            large={oldLogo}
            hideDownload={true}
            hideZoom={true}
          />
        </Category>
      </GridContainer>
    </Container>
  );
}

export default Activities;
