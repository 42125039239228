import { useEffect } from "react";
import styled from "styled-components";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { Carousel } from "react-responsive-carousel";

const Container = styled.div`
  width: 100%;
  height: 440px;
  padding: 0 20px;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  /* background-color: lemonchiffon; */
`;

const Title = styled.div`
  width: 100%;
  height: 40px;
  /* background-color: aliceblue; */
`;

const TitleSpan = styled.span`
  height: 100%;
  font-size: 2.1rem;
  background: linear-gradient(to right, #00a651, #005baa);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const CategoryWrapper = styled.div`
  width: 100%;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: red; */
`;

const CategoryCarousel = styled(Carousel)`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  li {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const Category = styled(motion.div)`
  width: 300px;
  height: 300px;
  margin: 0 40px;
  display: flex;
  flex-direction: column;
  border: 1px solid #ececec;
  border-image: linear-gradient(to right, #005baa 0%, #00a651 100%);
  border-image-slice: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  cursor: default;
  /* background-color: aliceblue; */
`;

const CategoryTitleContent = styled.div`
  width: 100%;
  height: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  background: linear-gradient(to left, #00a651, #005baa);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  /* background-color: red; */
`;

const CategoryDetailContent = styled.div`
  width: 100%;
  height: 50%;
  padding: 0 10%;
  display: flex;
  justify-content: center;
  /* align-items: center; */
  text-align: center;
  line-height: 1.4;
  font-size: 1rem;
  cursor: default;
  font-family: LineSeedKRRegular;
`;

function ResearchThemes() {
  const controls = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      controls.start("animate");
    }
    // else {
    //   controls.set("initial"); // animation starts on every inView Status
    // }
  }, [controls, inView]);
  return (
    <Container>
      <Title>
        <TitleSpan>Research Themes</TitleSpan>
      </Title>
      <CategoryWrapper ref={ref}>
        <CategoryCarousel
          showArrows={true}
          infiniteLoop={true}
          showStatus={false}
          showThumbs={false}
          showIndicators={false}
          autoPlay={true}
          preventMovementUntilSwipeScrollTolerance={true}
          swipeScrollTolerance={50}
        >
          <Category>
            <CategoryTitleContent>Smart Contract</CategoryTitleContent>
            <CategoryDetailContent>
              Smart Contract가 어떻게 동작하는지 알아보고, Solidity나 Rust와
              같은 언어를 통해 직접 구현해봅니다.
            </CategoryDetailContent>
          </Category>
          <Category>
            <CategoryTitleContent>Token Economy</CategoryTitleContent>
            <CategoryDetailContent>
              여러 Token Economy의 설계 방식을 알아보고, 직접 토큰에 적용해보는
              과정을 경험을 접해봅니다.
            </CategoryDetailContent>
          </Category>
          <Category>
            <CategoryTitleContent>DeFi</CategoryTitleContent>
            <CategoryDetailContent>
              DeFi가 어떻게 작동되는지 알아보고, Uniswap 등의 실제 Product들의
              사례를 연구해봅니다.
            </CategoryDetailContent>
          </Category>
          <Category>
            <CategoryTitleContent>NFT</CategoryTitleContent>
            <CategoryDetailContent>
              NFT의 기술 표준을 알아보고, 어떻게 구현되는지 알아봅니다. 직접 NFT
              Product를 만들어보고, NFT의 다양한 활용 가능성을 연구해봅니다.
            </CategoryDetailContent>
          </Category>
        </CategoryCarousel>
      </CategoryWrapper>
    </Container>
  );
}

export default ResearchThemes;
